import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import { FileText, Edit, MessageSquare, ChevronRight, TrendingUp, Users, Clock } from 'lucide-react';
import { Button } from "../components/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/tabs";
import { useInView } from 'react-intersection-observer'
import TypedJS from 'typed.js';
import HowItWorksSection from './HowItWorksSection';
import FAQSection from './FAQSection';

interface Feature {
  title: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  description: string;
  details: string;
}

interface FeatureCardProps {
  feature: Feature;
  isActive: boolean;
  onClick: () => void;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ feature, isActive, onClick }) => (
  <Card 
    className={`cursor-pointer transition-all duration-300 ${
      isActive ? 'bg-primary text-primary-foreground' : 'bg-card hover:bg-accent'
    }`}
    onClick={onClick}
  >
    <CardHeader>
      <feature.icon className={`w-10 h-10 ${isActive ? 'text-primary-foreground' : 'text-primary'}`} aria-hidden="true" />
      <CardTitle className="mt-4">{feature.title}</CardTitle>
    </CardHeader>
    <CardContent>
      <CardDescription className={isActive ? 'text-primary-foreground' : 'text-muted-foreground'}>
        {feature.description}
      </CardDescription>
    </CardContent>
    <CardFooter>
      <Button variant={isActive ? 'secondary' : 'ghost'} className="w-full justify-between group">
        <span>Learn More</span>
        <ChevronRight className="w-4 h-4 transition-transform group-hover:translate-x-1" aria-hidden="true" />
      </Button>
    </CardFooter>
  </Card>
);

interface Fact {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  fact: string;
  source: string;
}

interface FactCardProps {
  fact: Fact;
}

const FactCard: React.FC<FactCardProps> = ({ fact }) => (
  <Card className="bg-white shadow-lg h-full hover:shadow-xl transition-all duration-300">
    <CardContent className="p-4 md:p-6">
      <fact.icon className="w-8 h-8 md:w-12 md:h-12 text-yellow-400 mb-2 md:mb-4" />
      <p className="text-xs md:text-sm text-gray-600 mb-1 md:mb-2">{fact.fact}</p>
      <p className="text-xxs md:text-xs text-gray-400">Source: {fact.source}</p>
    </CardContent>
  </Card>
);

const recruitmentFacts: Fact[] = [
  {
    icon: FileText,
    fact: "More than 90% of large companies use Applicant Tracking Systems (ATS) to scan and filter résumés.",
    source: "JobScan, 2021"
  },
  {
    icon: MessageSquare,
    fact: "On average, each corporate job opening attracts 250 résumés.",
    source: "Glassdoor"
  },
  {
    icon: Edit,
    fact: "Recruiters spend an average of 7.4 seconds reviewing a résumé.",
    source: "Ladders, 2018"
  },
  {
    icon: FileText,
    fact: "63% of recruiters want to receive résumés tailored to the open position.",
    source: "JobVite"
  }
];

const features: Feature[] = [
  {
    title: "Job-Tailored Cover Letters",
    icon: FileText,
    description: "No more staring at a blank page! We'll help you craft a killer cover letter that speaks directly to the job you want.",
    details: "Just give us the job description, and we'll use AI magic to highlight your best qualities for that specific role. It's like having a career counselor in your pocket!"
  },
  {
    title: "Resume Tailoring Suggestions",
    icon: Edit,
    description: "Make your resume pop with personalized tips that'll catch any recruiter's eye.",
    details: "We'll analyze your resume and the job posting to suggest tweaks that'll make you stand out from the crowd. It's like giving your resume a makeover!"
  },
  {
    title: "Application Answer Generator",
    icon: MessageSquare,
    description: "Stuck on those tricky application questions? We've got your back!",
    details: "Based on your resume and the job description, we'll help you craft perfect answers to those open-ended questions. It's like having a cheat sheet for your dream job!"
  }
];

const FeaturesSection: React.FC = () => {
  return (
    <section id="features" className="py-12 md:py-24 bg-gradient-to-b from-[#c9e6e6] to-[#b8d8d8]">
      <div className="container mx-auto px-4 max-w-6xl">
        <div className="text-center space-y-4 mb-8 md:mb-16">
          <h2 className="text-3xl md:text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Your Personal Job Application Assistant
          </h2>
          <p className="mt-4 text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
            We've got the tools to make your job hunt a breeze. Check out how we can help you land that dream gig!
          </p>
        </div>

        <Tabs defaultValue="0" className="space-y-8 md:space-y-12">
          <TabsList className="flex flex-col md:flex-row justify-center md:space-x-8 border-b border-gray-300">
            {features.map((feature, index) => (
              <TabsTrigger 
                key={index} 
                value={index.toString()} 
                className="pb-2 md:pb-4 text-base md:text-lg font-medium text-gray-600 hover:text-gray-900 border-b-2 border-transparent data-[state=active]:border-yellow-400 data-[state=active]:text-gray-900"
              >
                {feature.title}
              </TabsTrigger>
            ))}
          </TabsList>
          {features.map((feature, index) => (
            <TabsContent key={index} value={index.toString()} className="mt-8 md:mt-12">
              <div className="grid md:grid-cols-2 gap-8 md:gap-12 items-center">
                <div className="space-y-4 md:space-y-6">
                  <h3 className="text-2xl md:text-3xl font-bold text-gray-900">{feature.title}</h3>
                  <p className="text-lg md:text-xl text-gray-600">{feature.description}</p>
                  <p className="text-base md:text-lg text-gray-700">{feature.details}</p>
                  <Button
                    className="bg-yellow-400 text-black hover:bg-yellow-500 px-4 md:px-6 py-2 rounded-full font-semibold text-sm md:text-base"
                    onClick={() => window.location.href = '/analyze'}
                  >
                    Try It Out
                  </Button>
                </div>
                <div className="bg-white rounded-lg shadow-lg p-4 md:p-8 flex items-center justify-center">
                  <feature.icon className="w-24 h-24 md:w-32 md:h-32 text-yellow-400" aria-hidden="true" />
                </div>
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </section>
  );
};

const LandingPage: React.FC = () => {
  const el = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const typed = new TypedJS(el.current, {
      strings: [
        'AI-Powered Guidance',
        'Tailored Cover Letter',
        'Optimized Resumes',
        'Application Answers'
      ],
      typeSpeed: 40,
      backSpeed: 50,
      loop: true
    });

    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-white via-[#e0f0f0] to-[#c9e6e6]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-between items-center py-4 md:py-6">
          <a href="/" className="flex items-center space-x-2">
            <img src="/favicon.ico" alt="WallysAI Logo" className="h-8 w-8 md:h-10 md:w-10" />
            <span className="text-lg md:text-xl font-bold text-gray-900">WallysAI</span>
          </a>
          <div className="hidden md:flex space-x-8">
            <a href="#features" className="text-gray-600 hover:text-gray-900">Features</a>
            <a href="#how-it-works" className="text-gray-600 hover:text-gray-900">How It Works</a>
            <a href="#faq" className="text-gray-600 hover:text-gray-900">FAQ</a>
          </div>
          <Button 
            className="bg-yellow-400 text-black hover:bg-yellow-500 px-4 md:px-6 py-2 rounded-full font-semibold text-sm md:text-base"
            onClick={() => window.location.href = '/analyze'}
          >
            Get started
          </Button>
        </nav>

        <main className="py-8 md:py-20">
          <div className="flex flex-col md:flex-row justify-between items-center min-h-[500px] md:min-h-[700px]">
            <div className="w-full md:w-1/4 space-y-4 md:space-y-8 md:pr-8 order-2 md:order-1">
              {recruitmentFacts.slice(0, 2).map((fact, index) => (
                <FactCard key={index} fact={fact} />
              ))}
            </div>
            
            <div className="w-full md:w-1/2 text-center order-1 md:order-2 mb-8 md:mb-0">
              <h1 className="text-4xl md:text-5xl lg:text-7xl font-bold mb-4 md:mb-6 text-gray-900">
                Boost Your Career with
              </h1>
              <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold mb-6 md:mb-12 bg-clip-text text-transparent bg-gradient-to-r from-yellow-400 to-yellow-600 h-[100px] md:h-[200px]">
                <span ref={el} aria-label="AI-Powered Guidance, Tailored Cover Letter, Optimized Resumes, Application Answers"></span>
              </h2>
              <p className="text-base md:text-xl mb-8 md:mb-12 max-w-2xl mx-auto text-gray-600">
                Say goodbye to application stress! We've got your back with AI-powered tools to craft 
                stellar cover letters, polish your resume, and nail those tricky application questions. 
                Let's make your dream job a reality, together!
              </p>
              <Button
                className="bg-yellow-400 text-black hover:bg-yellow-500 px-4 md:px-6 py-2 rounded-full font-semibold text-sm md:text-base"
                onClick={() => window.location.href = '/analyze'}
              >
                Get Started
              </Button>
            </div>
            
            <div className="w-full md:w-1/4 space-y-4 md:space-y-8 md:pl-8 order-3">
              {recruitmentFacts.slice(2, 4).map((fact, index) => (
                <FactCard key={index} fact={fact} />
              ))}
            </div>
          </div>
        </main>
      </div>

      <FeaturesSection />
      <HowItWorksSection />
      <FAQSection />
    </div>
  );
};

export default LandingPage;