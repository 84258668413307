import React from 'react';
import { motion } from 'framer-motion';
import { Upload, Sparkles, FileText } from 'lucide-react';
import { useInView } from 'react-intersection-observer';

const steps = [
  {
    icon: Upload,
    title: "Upload Your Resume",
    description: "Start by uploading your current resume or creating a new one from scratch using our intuitive builder."
  },
  {
    icon: Sparkles,
    title: "AI Analysis",
    description: "Our advanced AI analyzes your resume and the job description to identify key improvements and tailoring opportunities."
  },
  {
    icon: FileText,
    title: "Generate Application",
    description: "Receive a tailored cover letter, optimized resume, and application answers, all crafted to highlight your strengths."
  }
];

const HowItWorksSection: React.FC = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 15,
        stiffness: 100,
      },
    },
  };

  return (
    <section id="how-it-works" className="py-24 bg-gradient-to-b from-[#b8d8d8] to-white">
      <div className="container mx-auto px-4 max-w-6xl">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="space-y-16"
        >
          <div className="text-center">
            <motion.h2 variants={itemVariants} className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl mb-4">
              How It Works
            </motion.h2>
            <motion.p variants={itemVariants} className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
              Get your dream job in three simple steps with WallysAI
            </motion.p>
          </div>

          <div className="grid md:grid-cols-3 gap-12">
            {steps.map((step, index) => (
              <motion.div key={index} variants={itemVariants} className="flex flex-col items-center text-center">
                <div className="w-16 h-16 bg-teal-100 rounded-full flex items-center justify-center mb-6">
                  <step.icon className="w-8 h-8 text-teal-600" />
                </div>
                <div className="bg-white rounded-lg shadow-lg p-8 h-full flex flex-col">
                  <h3 className="text-2xl font-semibold text-gray-900 mb-4">{index + 1}. {step.title}</h3>
                  <p className="text-gray-600 flex-grow">{step.description}</p>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div variants={itemVariants} className="text-center">
            <a
              href="/analyze"
              onClick={(e) => { e.preventDefault(); window.location.href = '/analyze'; }}
              className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 md:py-4 md:text-lg md:px-10 transition duration-150 ease-in-out"
            >
              Get Started Now
            </a>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default HowItWorksSection;