import Anthropic from '@anthropic-ai/sdk';

const anthropic = new Anthropic({
  apiKey: process.env.REACT_APP_ANTHROPIC_API_KEY,
  dangerouslyAllowBrowser: true
});
const MAX_TOKENS = 2000;

function truncateText(text: string, maxTokens: number): string {
  const words = text.split(' ');
  let tokenCount = 0;
  let truncatedText = '';

  for (const word of words) {
    tokenCount += word.length / 4 + 1;
    if (tokenCount > maxTokens) break;
    truncatedText += word + ' ';
  }

  return truncatedText.trim();
}

async function claudeAPICall(prompt: string): Promise<string> {
  try {
    const response = await anthropic.messages.create({
      model: "claude-3-sonnet-20240229",
      max_tokens: 1000,
      messages: [{ role: "user", content: prompt }]
    });

    if (typeof response.content === 'string') {
      return response.content;
    } else if (Array.isArray(response.content) && response.content.length > 0) {
      return response.content
        .filter(block => block.type === 'text')
        .map(block => (block as { text: string }).text)
        .join('\n');
    } else {
      throw new Error('Unexpected response format from Anthropic API');
    }
  } catch (error) {
    console.error('Error calling Anthropic API:', error);
    throw error;
  }
}
export const generateCoverLetter = async (
  jobDescription: string,
  resumeContent: string,
  tone: 'informal' | 'semi-casual' | 'neutral' | 'semi-formal' | 'formal'
): Promise<string> => {
  try {
    const toneGuidelines = {
      informal: `
        - Use a very conversational and friendly tone
        - Include personal anecdotes or experiences freely
        - Use contractions, casual language, and even some slang (if appropriate)
        - Show lots of personality and humor where appropriate
        - Address the reader as if speaking to a close colleague or friend
      `,
      'semi-casual': `
        - Use a relaxed and approachable tone
        - Include some personal touches or anecdotes
        - Use contractions and a mix of casual and professional language
        - Show personality while maintaining a level of professionalism
        - Address the reader in a friendly manner, but not overly familiar
      `,
      neutral: `
        - Balance professionalism with a hint of personality
        - Use a mix of formal and casual language
        - Include some personal touches without being overly familiar
        - Maintain a friendly yet respectful tone
        - Address the reader professionally but warmly
      `,
      'semi-formal': `
        - Lean towards a more professional tone with a touch of personality
        - Use mostly formal language with occasional contractions
        - Include professional anecdotes or experiences where relevant
        - Show enthusiasm and interest while maintaining professionalism
        - Address the reader respectfully with a hint of warmth
      `,
      formal: `
        - Maintain a highly professional and respectful tone throughout
        - Use formal language and avoid contractions
        - Focus strictly on qualifications and achievements without personal anecdotes
        - Address the reader with formal salutations and closings
        - Emphasize professionalism, competence, and suitability for the role
      `
    };
    const prompt = `
    As an experienced career advisor specializing in crafting effective cover letters for job seekers, your task is to create a professional, concise, and tailored cover letter based on the provided job description and resume. The letter should highlight the candidate's relevant qualifications and express genuine interest in the specific role and company.
    
    Tone Guidelines:
    ${toneGuidelines[tone]}
    
    General Guidelines:
    1. Length: Aim for 200-300 words, prioritizing conciseness and relevance.
    2. Structure: Use 3-4 concise paragraphs with a clear, logical flow.
    3. Salutation: Use a professional greeting, such as "Dear Hiring Manager," if no specific name is available.
    4. Opening (1 paragraph): 
       - Clearly state the position you're applying for and how you learned about it.
       - Briefly mention your most relevant qualification or experience.
       - Express interest in the role and company in a professional manner.
    
    5. Body (1-2 paragraphs):
       - Focus on 2-3 key qualifications or experiences that directly align with the job requirements.
       - Use strong action verbs and incorporate relevant keywords from the job description.
       - Provide specific, quantifiable achievements where possible.
       - Demonstrate how your background makes you a strong fit for the role.
       - Show knowledge of the company by mentioning its mission or recent projects.
    
    6. Closing (1 paragraph):
       - Reiterate your interest in the position and company.
       - Express your enthusiasm for the opportunity to discuss your qualifications further.
       - Include a professional call-to-action, such as your availability for an interview.
       - Close with a formal sign-off, such as "Sincerely" or "Best regards."
    
    7. Overall:
       - Maintain a professional and focused tone throughout.
       - Ensure the letter is tailored to the specific job and company.
       - Use clear, concise language without unnecessary elaboration.
       - Proofread carefully for grammar, spelling, and overall professionalism.
    
    Job Description:
    ${truncateText(jobDescription, MAX_TOKENS)}
    
    Resume:
    ${truncateText(resumeContent, MAX_TOKENS)}
    
    Now, write a professional and effective cover letter as the candidate, adhering to the ${tone} tone and guidelines provided. Focus on creating a concise, relevant, and compelling letter that clearly demonstrates the candidate's suitability for the role.`;
    return await claudeAPICall(prompt);
  } catch (error) {
    console.error('Error generating cover letter:', error);
    throw error;
  }
};

export interface ResumeSuggestion {
  section: string;
  original: string;
  suggestion: string;
  reason: string;
  highlightedKeywords: string[];
}

export const analyzeResume = async (
  jobDescription: string,
  resumeContent: string
): Promise<ResumeSuggestion[]> => {
  try {
    const prompt = `Analyze the provided resume against the job description to suggest high-impact enhancements that will significantly improve its effectiveness for both Applicant Tracking Systems (ATS) and human recruiters. Focus on tailoring the resume to the specific role and company culture. Follow these detailed steps:

1. Keyword and Skills Analysis:
   - Identify critical hard skills, soft skills, qualifications, and experiences from the job description.
   - Suggest incorporating these keywords naturally throughout the resume, especially in the summary and skills sections.
   - Recommend removing or deprioritizing irrelevant skills or experiences.

2. Achievement Optimization:
   - Improve key achievements using the enhanced XYZ method: "Accomplished [X] as measured by [Y], by doing [Z], resulting in [business impact]".
   - Ensure each achievement is quantifiable, specific, and demonstrates the candidate's unique value.
   - Prioritize recent and highly relevant achievements.

3. ATS Compatibility and Readability:
   - Suggest improvements for ATS-friendly formatting, including standard section headings and consistent structure.
   - Recommend changes to enhance scannability for both ATS and human readers (e.g., bullet point structure, white space usage).

4. Tailoring to Company and Role:
   - Propose adjustments that align the resume with the company's culture, values, and specific job requirements.
   - Suggest incorporating relevant industry buzzwords and trending skills.

5. Technical Proficiency (if applicable):
   - For tech roles, ensure all relevant tools, technologies, and methodologies are clearly listed and contextualized.

6. Soft Skills Emphasis:
   - Recommend ways to highlight crucial soft skills through specific examples and achievements.

For each suggestion, provide:

The section of the resume (e.g., Work Experience, Skills, Education).
The original bullet point or sentence.
The suggested improvement, using the XYZ method where applicable.
A brief explanation of why this change is beneficial.
A list of keywords that are highlighted or added.

Respond in the following JSON format:
[
  {
    "section": "Section Name",
    "original": "Original bullet point or sentence",
    "suggestion": "Improved bullet point or sentence",
    "reason": "Brief explanation of the improvement",
    "highlightedKeywords": ["keyword1", "keyword2"]
  }
]

Prioritize the most impactful changes that will significantly improve the resume's effectiveness for this specific job application. Aim to provide 3-5 high-quality suggestions.

Resume:
${truncateText(resumeContent, MAX_TOKENS)}

Job Description:
${truncateText(jobDescription, MAX_TOKENS)}`;

    const suggestionsContent = await claudeAPICall(prompt);

    // Sanitize and parse the response
    const sanitizedContent = suggestionsContent.replace(/\n/g, "").replace(/\r/g, "").replace(/\t/g, "").trim();
    const jsonContent = sanitizedContent.slice(sanitizedContent.indexOf("["), sanitizedContent.lastIndexOf("]") + 1);

    return JSON.parse(jsonContent) as ResumeSuggestion[];
  } catch (error) {
    console.error('Error analyzing resume suggestions:', error);
    throw new Error('Failed to analyze resume. Please try again.');
  }
};


export interface ApplicationQuestion {
  question: string;
  answer: string;
}

export const generateApplicationAnswers = async (
  jobDescription: string,
  resumeContent: string,
  applicationQuestions: string[]
): Promise<ApplicationQuestion[]> => {
  try {
    const prompt = `Based on the provided job description, resume, and specific application questions, generate natural-sounding, tailored answers for each question. Aim for the best possible outcome that will impress the hiring manager and increase the chances of getting an interview.Make sure to answer personal questions or topics using the information from the resume and not make up lies only can exxagorate the truth from the resume.

Job Description:
${truncateText(jobDescription, MAX_TOKENS)}

Resume:
${truncateText(resumeContent, MAX_TOKENS)}

Guidelines for answers:
1. Use a conversational, natural tone as if speaking in an interview
2. Incorporate specific examples and brief anecdotes from the resume where relevant
3. Include quantifiable achievements where possible
4. Demonstrate knowledge of the company and enthusiasm for the role
5. Address any potential gaps or mismatches proactively
6. Balance confidence with humility
7. Keep answers concise (100-150 words) but impactful
8. Tailor each answer to the specific job, company, and candidate profile
9. Use contractions and occasional colloquialisms naturally to sound more human
10. Occasionally reference current events or industry trends if relevant

Please provide answers to the following application questions:

${applicationQuestions.map((q, index) => `${index + 1}. ${q}`).join('\n')}

Respond in the following JSON format:
[
  {
    "question": "Question text",
    "answer": "Natural-sounding, tailored answer"
  }
]`;

    const responseContent = await claudeAPICall(prompt);
    console.log('Raw API response:', responseContent); // For debugging

    try {
      const jsonContent = JSON.parse(responseContent);
      if (Array.isArray(jsonContent)) {
        return jsonContent as ApplicationQuestion[];
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError);
      throw new Error('Failed to parse application answers. Please try again.');
    }
  } catch (error) {
    console.error('Error generating application answers:', error);
    throw new Error('Failed to generate application answers. Please try again.');
  }
};