import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FileText, Upload, Copy, FileEdit, HelpCircle, Download, ThumbsUp, ThumbsDown, Check, Linkedin, RefreshCw, Home, FileTextIcon, Users, Briefcase, Settings, MessageCircle, ChevronDown, ChevronUp, Menu, X, ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from "../components/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../components/card";
import { generateCoverLetter, analyzeResume, ResumeSuggestion, generateApplicationAnswers, ApplicationQuestion } from '../services/openai';
import { extractTextFromPDF } from '../pdfjs-wrapper';
import { jsPDF } from 'jspdf';
import AnimatedLoading from './AnimatedLoading';

const JobAnalysisPage: React.FC = () => {
  const [activeSection, setActiveSection] = useState('getStarted');
  const [jobDescription, setJobDescription] = useState('');
  const [resume, setResume] = useState<File | null>(null);
  const [resumeContent, setResumeContent] = useState<string>('');
  const [coverLetter, setCoverLetter] = useState<string>('');
  const [resumeSuggestions, setResumeSuggestions] = useState<ResumeSuggestion[]>([]);
  const [applicationQuestions, setApplicationQuestions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<Record<string, 'like' | 'dislike' | null>>({});
  const [copiedStates, setCopiedStates] = useState<Record<string, boolean>>({});
  const [analysisComplete, setAnalysisComplete] = useState<boolean>(false);
  const [applicationAnswers, setApplicationAnswers] = useState<ApplicationQuestion[]>([]);
  const [coverLetterTone, setCoverLetterTone] = useState<'informal' | 'semi-casual' | 'neutral' | 'semi-formal' | 'formal'>('neutral');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const MobileMenu: React.FC = () => (
    <motion.div
      initial={{ x: '-100%' }}
      animate={{ x: 0 }}
      exit={{ x: '-100%' }}
      transition={{ type: 'tween' }}
      className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg z-50 p-4 md:hidden"
    >
      <div className="flex justify-between items-center mb-6">
        <span className="text-xl font-bold">WallysAI</span>
        <Button variant="ghost" onClick={toggleMobileMenu}>
          <X size={24} />
        </Button>
      </div>
      <nav className="space-y-4">
        <SidebarItem icon={Home} label="Get Started" section="getStarted" />
        <SidebarItem icon={FileTextIcon} label="Cover Letter" section="coverLetter" disabled={!analysisComplete} />
        <SidebarItem icon={FileEdit} label="Resume Improvements" section="improvements" disabled={!analysisComplete} />
        <SidebarItem icon={HelpCircle} label="Application Questions" section="questions" disabled={!analysisComplete} />
      </nav>
    </motion.div>
  );



  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    if (!resume) {
      setError('Please upload your resume before analyzing.');
      return;
    }
    if (!resumeContent) {
      setError('Unable to read resume content. Please try uploading again.');
      return;
    }
    setIsLoading(true);
    await attemptAnalysis();
  };

  const attemptAnalysis = async () => {
    try {
      const [letter, suggestions] = await Promise.all([
        generateCoverLetter(jobDescription, resumeContent, coverLetterTone),
        analyzeResume(jobDescription, resumeContent),
      ]);
      setCoverLetter(letter);
      setResumeSuggestions(suggestions);
      setActiveSection('coverLetter');
      setAnalysisComplete(true);
      setRetryCount(0); // Reset retry count on success
      setIsLoading(false); // Ensure loading is set to false on success
    } catch (error) {
      console.error('Analysis error:', error);
      if (retryCount < MAX_RETRIES) {
        setRetryCount(prev => prev + 1);
        setTimeout(attemptAnalysis, 1000); // Retry after 1 second
      } else {
        setError('An error occurred during analysis. Please try again.');
        setIsLoading(false); // Ensure loading is set to false after all retries
      }
    }
  };

  useEffect(() => {
    if (retryCount > 0 && retryCount <= MAX_RETRIES) {
      setError(`Analysis attempt ${retryCount} of ${MAX_RETRIES}...`);
    }
  }, [retryCount]);

  // Add a new useEffect to handle any unexpected states
  useEffect(() => {
    if (analysisComplete && isLoading) {
      setIsLoading(false); // Ensure loading is false if analysis is complete
    }
  }, [analysisComplete, isLoading]);

  const handleNewAnalysis = () => {
    setActiveSection('getStarted');
    setJobDescription('');
    setResume(null);
    setResumeContent('');
    setCoverLetter('');
    setResumeSuggestions([]);
    setApplicationQuestions([]);
    setAnalysisComplete(false);
    setError(null);
    setFeedback({});
    setCopiedStates({});
  };

  const handleResumeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setResume(file);
      try {
        const content = await readFileContent(file);
        setResumeContent(content);
        setResumeUploaded(true);
      } catch (error) {
        console.error('Error reading resume file:', error);
        setError('Error reading resume file. Please try again.');
        setResumeUploaded(false);
      }
    }
  };
  const readFileContent = async (file: File): Promise<string> => {
    if (file.type === 'application/pdf') {
      const arrayBuffer = await file.arrayBuffer();
      return extractTextFromPDF(arrayBuffer);
    } else {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target?.result as string);
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
      });
    }
  };

  const handleDownloadPDF = (content: string, filename: string) => {
    const doc = new jsPDF();
    doc.setFont('Times', 'Roman');
    doc.setFontSize(12);
    const splitText = doc.splitTextToSize(content, 180);
    doc.text(splitText, 15, 15);
    doc.save(filename);
  };

  const handleCopy = (content: string, key: string) => {
    navigator.clipboard.writeText(content);
    setCopiedStates(prev => ({ ...prev, [key]: true }));
    setTimeout(() => {
      setCopiedStates(prev => ({ ...prev, [key]: false }));
    }, 2000);
  };

  const CopyButton: React.FC<{ content: string, id: string }> = ({ content, id }) => (
    <Button
      onClick={() => handleCopy(content, id)}
      className="bg-[#44A0B0] text-white hover:bg-[#2C7A7B] transition duration-300 flex items-center gap-2"
    >
      {copiedStates[id] ? <Check size={16} /> : <Copy size={16} />}
      {copiedStates[id] ? 'Copied' : 'Copy'}
    </Button>
  );

  const YellowButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...props }) => (
    <Button
      {...props}
      className="bg-yellow-400 text-gray-800 hover:bg-yellow-500 hover:text-gray-900 font-semibold shadow-md transition-all duration-300 ease-in-out transform hover:scale-105 rounded-full px-6 py-2"
    >
      {children}
    </Button>
  );
  const GetStartedSection: React.FC = () => (
    <Card className="w-full mx-auto bg-white rounded-xl shadow-lg">
      <CardHeader>
        <CardTitle className="text-2xl md:text-3xl font-bold text-gray-900">Get Started with Your Job Application</CardTitle>
        <CardDescription className="text-sm md:text-base text-gray-600">Upload your resume and input the job description to begin the analysis.</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <div 
            className={`flex flex-col items-center justify-center p-4 rounded-lg cursor-pointer transition duration-300 ${resumeUploaded ? 'bg-green-100 hover:bg-green-200' : 'bg-[#E0F7FA] hover:bg-[#B2EBF2]'}`}
            onClick={() => document.getElementById('resumeUpload')?.click()}
          >
            {resumeUploaded ? (
              <>
                <Check size={32} className="text-green-500 mb-2" />
                <span className="text-base font-semibold text-green-700">Resume Uploaded</span>
              </>
            ) : (
              <>
                <Upload size={32} className="text-[#5f9ea0] mb-2" />
                <span className="text-base font-semibold text-[#5f9ea0]">Upload Resume</span>
              </>
            )}
            <span className="text-xs text-gray-500 mt-1">(PDF Format)</span>
          </div>
          <div className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg opacity-50">
            <Linkedin size={32} className="text-gray-400 mb-2" />
            <span className="text-base font-semibold text-gray-400">LinkedIn Import</span>
            <span className="text-xs text-gray-500 mt-1">(Coming Soon)</span>
          </div>
        </div>
        
        <input
          id="resumeUpload"
          type="file"
          accept=".pdf,.docx"
          onChange={handleResumeUpload}
          className="hidden"
        />
        
        <h2 className="text-xl font-bold text-gray-900 mb-2">Input Job Description</h2>
        <textarea
          placeholder="Paste the job description here..."
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
          className="w-full h-32 md:h-48 p-2 md:p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5f9ea0] mb-4"
        />
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Cover Letter Tone:</h3>
          <div className="flex flex-wrap justify-start gap-2">
            {['informal', 'semi-casual', 'neutral', 'semi-formal', 'formal'].map((tone) => (
              <label key={tone} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  value={tone}
                  checked={coverLetterTone === tone}
                  onChange={(e) => setCoverLetterTone(e.target.value as typeof coverLetterTone)}
                  className="sr-only"
                />
                <span className={`px-3 py-1 text-sm rounded-full ${coverLetterTone === tone ? 'bg-[#5f9ea0] text-white' : 'bg-gray-200 text-gray-700'} transition-colors duration-200`}>
                  {tone.charAt(0).toUpperCase() + tone.slice(1)}
                </span>
              </label>
            ))}
          </div>
        </div>
        <div className="flex justify-center">
          <Button 
            onClick={handleSubmit} 
            disabled={!resumeUploaded || !jobDescription.trim()}
            className="bg-yellow-400 text-gray-800 hover:bg-yellow-500 font-semibold px-4 py-2 rounded-full text-sm md:text-base"
          >
            Analyze Job Description
          </Button>
        </div>
      </CardContent>
    </Card>
  );

  const Sidebar: React.FC = () => (
    <motion.div
      className={`fixed left-0 top-0 h-full bg-white shadow-lg z-40 transition-all duration-300 ease-in-out ${
        isSidebarOpen ? 'w-64' : 'w-16'
      } hidden md:block`}
      initial={false}
      animate={{ width: isSidebarOpen ? 256 : 64 }}
    >
      <div className="p-4 flex items-center justify-between">
        <div className="flex items-center">
          <FileText className="h-8 w-8 text-[#5f9ea0]" />
          {isSidebarOpen && <span className="text-xl font-bold text-gray-900 ml-2">WallysAI</span>}
        </div>
        <Button
          variant="ghost"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="hidden md:block"
        >
          {isSidebarOpen ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
        </Button>
      </div>
      <nav className="mt-8">
        <SidebarItem icon={Home} label="Get Started" section="getStarted" />
        <SidebarItem icon={FileTextIcon} label="Cover Letter" section="coverLetter" disabled={!analysisComplete} />
        <SidebarItem icon={FileEdit} label="Resume Improvements" section="improvements" disabled={!analysisComplete} />
        <SidebarItem icon={HelpCircle} label="Application Questions" section="questions" disabled={!analysisComplete} />
      </nav>
    </motion.div>
  );

  const SidebarItem: React.FC<{ icon: React.ElementType, label: string, section: string, disabled?: boolean }> = ({ icon: Icon, label, section, disabled }) => (
    <div 
      className={`flex items-center p-4 cursor-pointer ${activeSection === section ? 'bg-[#E0F7FA] text-[#5f9ea0]' : 'text-gray-600 hover:bg-gray-100'} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={() => !disabled && setActiveSection(section)}
    >
      <Icon size={20} />
      {isSidebarOpen && <span className="ml-4">{label}</span>}
    </div>
  );

  const CoverLetterSection: React.FC = () => (
    <Card>
      <CardHeader>
        <CardTitle>Generated Cover Letter</CardTitle>
        <CardDescription>Your AI-crafted cover letter, tailored to the job description and your resume.</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex justify-between items-center mb-6">
          <div className="flex gap-4">
            <Button 
              variant="outline"
              onClick={() => handleDownloadPDF(coverLetter, 'cover_letter.pdf')}
              className="flex items-center"
            >
              <Download size={20} className="mr-2" />
              Download PDF
            </Button>
            <CopyButton content={coverLetter} id="coverLetter" />
          </div>
        </div>
        <div className="bg-gray-50 p-6 rounded-lg mb-6 whitespace-pre-wrap font-serif text-gray-800 max-h-96 overflow-y-auto">
          {coverLetter}
        </div>
      </CardContent>
      <CardFooter className="flex justify-between items-center">
        <div className="text-sm text-gray-500">
          How do you like this cover letter?
        </div>
        <div className="flex gap-4">
          {['like', 'dislike'].map((type) => (
            <Button
              key={type}
              variant="outline"
              onClick={() => setFeedback(prev => ({ ...prev, coverLetter: type as 'like' | 'dislike' }))}
              className={`flex items-center ${feedback.coverLetter === type ? 'bg-[#E0F7FA] border-[#5f9ea0]' : ''}`}
            >
              {type === 'like' ? <ThumbsUp size={20} className="mr-2" /> : <ThumbsDown size={20} className="mr-2" />}
              {type === 'like' ? 'I Like It' : "I Don't Like It"}
            </Button>
          ))}
        </div>
      </CardFooter>
    </Card>
  );

  const ResumeImprovementsSection: React.FC = () => {
    const [expandedSuggestion, setExpandedSuggestion] = useState<number | null>(null);
  
    const handleCopyResume = (content: string, id: string, event: React.MouseEvent) => {
      event.stopPropagation(); // Prevent the click from bubbling up to the parent div
      navigator.clipboard.writeText(content);
      setCopiedStates(prev => ({ ...prev, [id]: true }));
      setTimeout(() => {
        setCopiedStates(prev => ({ ...prev, [id]: false }));
      }, 2000);
    };
  
    return (
      <Card>
        <CardHeader>
          <CardTitle>Resume Improvements</CardTitle>
          <CardDescription>AI-powered suggestions to enhance your resume and increase your chances of landing the job.</CardDescription>
        </CardHeader>
        <CardContent>
          {resumeSuggestions.map((suggestion, index) => (
            <motion.div 
              key={index} 
              className="mb-8 bg-gray-50 rounded-lg shadow-sm overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div 
                className="p-4 bg-[#E0F7FA] flex justify-between items-center cursor-pointer"
                onClick={() => setExpandedSuggestion(expandedSuggestion === index ? null : index)}
              >
                <h3 className="text-xl font-semibold text-[#5f9ea0]">{suggestion.section}</h3>
                {expandedSuggestion === index ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
              </div>
              <AnimatePresence>
                {expandedSuggestion === index && (
                  <motion.div
                    initial={{ height: 0 }}
                    animate={{ height: 'auto' }}
                    exit={{ height: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <div className="p-6">
                      <div className="mb-4">
                        <p className="font-medium text-gray-700 mb-2">Original:</p>
                        <p className="bg-white p-3 rounded border border-gray-200">{suggestion.original}</p>
                      </div>
                      <div className="mb-4">
                        <p className="font-medium text-gray-700 mb-2">Suggestion:</p>
                        <p className="bg-white p-3 rounded border border-gray-200">{suggestion.suggestion}</p>
                      </div>
                      <div>
                        <p className="font-medium text-gray-700 mb-2">Reason:</p>
                        <p className="text-gray-600">{suggestion.reason}</p>
                      </div>
                      <Button
                        onClick={(e) => handleCopyResume(suggestion.suggestion, `suggestion-${index}`, e)}
                        className="bg-[#44A0B0] text-white hover:bg-[#2C7A7B] transition duration-300 flex items-center gap-2 mt-4"
                      >
                        {copiedStates[`suggestion-${index}`] ? <Check size={16} /> : <Copy size={16} />}
                        {copiedStates[`suggestion-${index}`] ? 'Copied' : 'Copy'}
                      </Button>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </CardContent>
      </Card>
    );
  };
  const ApplicationQuestionsSection: React.FC = () => {
    const [newQuestion, setNewQuestion] = useState('');
  
    const handleAddQuestion = () => {
      if (newQuestion.trim()) {
        setApplicationQuestions(prev => [...prev, newQuestion.trim()]);
        setNewQuestion('');
      }
    };
  
    const handleGenerateAnswers = async () => {
      if (applicationQuestions.length === 0) {
        setError('Please add at least one application question.');
        return;
      }
      setIsLoading(true);
      try {
        const answers = await generateApplicationAnswers(jobDescription, resumeContent, applicationQuestions);
        setApplicationAnswers(answers);
      } catch (error) {
        console.error('Error generating application answers:', error);
        setError(error instanceof Error ? error.message : 'An error occurred while generating answers. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Card>
        <CardHeader>
          <CardTitle>Application Questions</CardTitle>
          <CardDescription>Add specific questions from your job application to get tailored answers.</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-6">
            <div className="flex gap-4">
              <input
                type="text"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                placeholder="Enter a question from your application"
                className="flex-grow p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#5f9ea0]"
              />
              <Button onClick={handleAddQuestion} disabled={!newQuestion.trim()}>
                Add
              </Button>
            </div>
          </div>
    
          {applicationQuestions.length > 0 && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold text-[#5f9ea0] mb-4">Your Questions:</h3>
              <ul className="list-disc pl-5 space-y-2">
                {applicationQuestions.map((q, index) => (
                  <li key={index} className="text-gray-700">{q}</li>
                ))}
              </ul>
              <Button onClick={handleGenerateAnswers} className="mt-6">
                Generate Answers
              </Button>
            </div>
          )}
    
          {applicationAnswers.length > 0 && (
            <div>
              <h3 className="text-xl font-semibold text-[#5f9ea0] mb-6">Generated Answers:</h3>
              {applicationAnswers.map((qa, index) => (
                <motion.div 
                  key={index} 
                  className="mb-6 bg-gray-50 rounded-lg p-6 shadow-sm"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <p className="font-semibold text-lg text-[#5f9ea0] mb-2">{qa.question}</p>
                  <p className="text-gray-700 mb-4">{qa.answer}</p>
                  <CopyButton content={qa.answer} id={`answer-${index}`} />
                </motion.div>
              ))}
            </div>
          )}
        </CardContent>
      </Card>
    );
  };
  return (
    <div className="flex h-screen bg-gradient-to-b from-[#E0F7FA] to-[#5f9ea0]">
      {/* Sidebar */}
      <motion.div
        className={`fixed left-0 top-0 h-full bg-white shadow-lg z-40 transition-all duration-300 ease-in-out ${
          isSidebarOpen ? 'w-64' : 'w-16'
        } hidden md:block`}
        initial={false}
        animate={{ width: isSidebarOpen ? 256 : 64 }}
      >
        <div className="p-4 flex items-center justify-between">
          <div className="flex items-center">
            <FileText className="h-8 w-8 text-[#5f9ea0]" />
            {isSidebarOpen && <span className="text-xl font-bold text-gray-900 ml-2">WallysAI</span>}
          </div>
          <Button
            variant="ghost"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="hidden md:block"
          >
            {isSidebarOpen ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
          </Button>
        </div>
        <nav className="mt-8">
          <SidebarItem icon={Home} label="Get Started" section="getStarted" />
          <SidebarItem icon={FileTextIcon} label="Cover Letter" section="coverLetter" disabled={!analysisComplete} />
          <SidebarItem icon={FileEdit} label="Resume Improvements" section="improvements" disabled={!analysisComplete} />
          <SidebarItem icon={HelpCircle} label="Application Questions" section="questions" disabled={!analysisComplete} />
        </nav>
      </motion.div>

      {/* Main content */}
      <div className={`flex-1 flex flex-col transition-all duration-300 ${isSidebarOpen ? 'md:ml-64' : 'md:ml-16'}`}>
        {/* Header */}
        <header className="bg-white py-2 px-4 shadow-md sticky top-0 z-10">
          <div className="container mx-auto flex justify-between items-center">
            <div className="flex items-center">
              <Button
                variant="ghost"
                onClick={toggleMobileMenu}
                className="mr-2 md:hidden"
              >
                <Menu size={24} />
              </Button>
              <h1 className="text-xl font-bold text-gray-900">Job Analysis</h1>
            </div>
            <Button 
              onClick={handleNewAnalysis}
              className="bg-yellow-400 text-gray-800 hover:bg-yellow-500 font-semibold px-4 py-2 rounded-full text-sm"
            >
              New Analysis
            </Button>
          </div>
        </header>

        {/* Main content area */}
        <main className="flex-1 overflow-y-auto p-4">
          <AnimatePresence>
            {isMobileMenuOpen && <MobileMenu />}
          </AnimatePresence>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {activeSection === 'getStarted' && <GetStartedSection />}
            {activeSection === 'coverLetter' && analysisComplete && <CoverLetterSection />}
            {activeSection === 'improvements' && analysisComplete && <ResumeImprovementsSection />}
            {activeSection === 'questions' && analysisComplete && <ApplicationQuestionsSection />}
          </motion.div>

          {isLoading && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            >
              <div className="bg-white p-8 rounded-xl shadow-lg text-center">
                <AnimatedLoading />
                <h2 className="text-2xl font-bold text-[#5f9ea0] mt-4">Analyzing...</h2>
              </div>
            </motion.div>
          )}

          <AnimatePresence>
            {error && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="fixed bottom-4 right-4 bg-red-500 text-white p-4 rounded-lg shadow-lg"
              >
                <p className="font-semibold">Error:</p>
                <p>{error}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </main>
      </div>
</div>
  );
};

export default JobAnalysisPage;