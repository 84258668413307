import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../components/animation.json';

const AnimatedLoading: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-xl shadow-lg text-center">
        <Lottie options={defaultOptions} height={200} width={200} />
        <h2 className="text-2xl font-bold text-[#2C7A7B] mt-4">Analyzing Your Application</h2>
        <p className="text-gray-600 mt-2">We're crafting ...</p>
      </div>
    </div>
  );
};

export default AnimatedLoading;