import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

const faqs = [
  {
    question: "How does WallysAI generate cover letters?",
    answer: "WallysAI uses advanced natural language processing to analyze job descriptions and your resume, creating tailored cover letters that highlight your most relevant skills and experiences."
  },
  {
    question: "Is WallysAI suitable for students with no work experience",
    answer: "Absolutely! Our AI is designed to help students highlight their academic achievements, projects, and extracurricular activities to create compelling applications."
  },
  {
    question: "How does WallysAI ensure my application stands out?",
    answer: "WallysAI analyzes the job description and tailors your application to match the key requirements, ensuring your unique skills and experiences are highlighted effectively."
  },
  {
    question: "What AI model does WallysAI use?",
    answer: "WallysAI utilizes the advanced Claude 3.5 Sonnet AI model, ensuring high-quality, context-aware responses for all your job application needs."
  }
];

const FAQItem: React.FC<{ item: { question: string; answer: string } }> = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-medium text-gray-900">{item.question}</span>
        <ChevronDown className={`w-5 h-5 text-gray-500 transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="mt-2 text-gray-600">{item.answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQSection: React.FC = () => {
  return (
    <section id="faq" className="py-24 bg-[#b8d8d8]">
      <div className="container mx-auto px-4 max-w-3xl">
        <h2 className="text-4xl font-bold text-center mb-12">Frequently Asked Questions</h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem key={index} item={faq} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;